<template>
  <div>
    <Header
      :showBackButton="false"
      :showMenuIcon="false"
      :showDesktopNavbar="false"
      class="page-anim1 mt-1"
    />

    <section>
      <div class="container">
        <div class="text-holder mt-3">
          <span class="title main-title">TERMOS DE USO APLICATIVO SIBER</span>
          <p class="mt-2">
            KERRY DO BRASIL LTDA, devidamente inscrita no CNPJ/MF sob o nº
            02.332.686/0001-43, com sede na Avenida Mercedes Benz, n. 406,
            Bairro Distrito Industrial, na cidade de Campinas/SP, CEP:
            13090-740, denominada a partir deste ponto, no presente documento
            por sua denominação institucional KERRY DO BRASIL, única e exclusiva
            proprietária das marcas e dos domínios associados ao
            <b>“Aplicativo SIBER”</b>, estabelece as presentes condições de uso
            de seu Aplicativo, nos seguintes termos:
          </p>
        </div>
        <div class="text-holder mt-3">
          <p><b>1) </b><span class="title">DAS DEFINIÇÕES</span></p>
          <p class="mt-2">
            Para os fins destes <b>Termos de Uso</b>, as designações abaixo
            terão os seguintes significados: <b>Aceitar ou Aceite</b>: refere-se
            ao ato do <b>USUÁRIO</b> clicar na caixa “Li e aceito os Termos de
            Uso e Política de Privacidade” disposta no
            <b>Aplicativo SIBER</b> tal ato implicará no consentimento prévio,
            expresso, inequívoco e informado do USUÁRIO em relação a todo o
            conteúdo disposto em tais documentos, autorizando, assim, o
            armazenamento de base de dados composta por Dados Pessoais, entre
            outras informações.
          </p>
          <p class="mt-2">
            <b>Aplicativo SIBER:</b> define-se o aplicativo desenvolvido para
            operação em smartphone que tenha o sistema operacional IOS ou
            Android, nas versões mínimas, com acesso à internet banda larga (3G
            ou 4G).
          </p>
          <p class="mt-2">
            <b>Dados Pessoais:</b> significa todo e qualquer dado
            disponibilizado ou fornecido pelo <b>USUÁRIO</b> que, de alguma
            forma, o identifique ou o torne identificável, como,
            exemplificativamente, nome, endereço, CPF, número de telefone,
            endereço de e-mail e quaisquer outras informações desta natureza.
          </p>
          <p class="mt-2">
            <b>SIBER:</b> se refere a uma marca do
            <b>GRUPO KERRY expressamente qualificada no preâmbulo</b>
            deste instrumento.
          </p>
          <p class="mt-2">
            <b>Política de Privacidade:</b> significa a política de privacidade
            que disciplina e regula a utilização dos dados do
            <b>USUÁRIO</b> disponibilizados no <b>Aplicativo SIBER</b>.
          </p>
          <p class="mt-2">
            <b>Termos de Uso:</b> os presentes Termos aplicáveis ao
            <b>Aplicativo SIBER</b>.
          </p>
          <p class="mt-2">
            <b>USUÁRIO</b>: a pessoa física, com plena capacidade de contratar,
            nos termos da lei, que tenha efetuado o download e instalado o
            aplicativo e que venha a acessar o <b>Aplicativo SIBER</b>,
            realizando o seu cadastro pessoal, de modo a usufruir das
            funcionalidades oferecidas por ele, aceitando, expressamente, nessa
            oportunidade, incondicionalmente, os presentes
            <b>Termos de Uso</b> e a <b>Política de Privacidade</b>.
          </p>
        </div>
        <div class="text-holder mt-3">
          <p><b>2) </b><span class="title">DA FORMALIZAÇÃO DO ACEITE</span></p>
          <p class="mt-2">
            <b
              >O ACEITE DO PRESENTE “TERMOS DE USO DO APP SIBER” NO CAMPO “LI E
              ACEITO” E FORNECIMENTO DAS INFORMAÇÕES CADASTRAIS SOLICITADAS IRÁ
              IMPLICAR NO RECONHECIMENTO, PELO USUÁRIO, DE QUE LEU, ENTENDEU E
              ACEITOU Kerry Group Confidential INCONDICIONALMENTE TODAS AS
              DISPOSIÇÕES CONSTANTES NESTE INSTRUMENTO, ADMITINDO QUE PRETENDE
              RECEBER E FORNECER INFORMAÇÕES, ALÉM DE PARTICIPAR DE POSSÍVEIS
              PROMOÇÕES E DIVULGAÇÃO DE PRODUTOS NOS EXATOS TERMOS ORA
              ESTABELECIDOS, SEM RESERVAS. Em razão disso, recomenda-se a
              leitura integral deste instrumento e, caso não esteja confortável
              ou de acordo com as condições aqui previstas, NÃO PROSSIGA COM
              ACEITAÇÃO/CADASTRO NO APLICATIVO.</b
            >
          </p>
        </div>
        <div class="text-holder mt-2">
          <p>
            <b>3) </b
            ><span class="title">QUAIS OS BENEFÍCIOS DO APLICATIVO SIBER?</span>
          </p>
          <p class="mt-2">
            O <b>USUÁRIO</b>, após
            <b>se cadastrar e acessar o Aplicativo Siber</b>, usufruirá dos
            seguintes benefícios de <u>maneira totalmente gratuita</u>:
          </p>
          <ol class="ml-3 mt-1">
            <li>
              Acesso aos receituários, produtos Siber, artigos e lançamentos da
              marca e de seus parceiros;
            </li>
            <li>
              Receituários desenvolvidos pela Equipe Técnica da Kerry,
              utilizando os produtos Siber e de outras marcas Kerry;
            </li>
            <li>
              Calculadora de Receitas: estará disponível ao usuário uma
              calculadora que permitirá e facilitará a medição dos ingredientes,
              bem como o cálculo correto para desenvolver o produto
              desejado/rendimento e saber quais os custos e a lucratividade
              daquela receita;
              <p>
                a. É de responsabilidade do usuário a inclusão e atualização dos
                valores de cada item ou produto para melhor precisão do cálculo
                de valores
              </p>
            </li>
            <li>
              É permitido ao usuário cadastrado, incluir sua própria receita ou
              modificar a receita desenvolvida pelos técnicos da Kerry, para
              melhor adequação dos seus produtos/estabelecimento/realidade;
            </li>
            <li>
              Eventualmente poderão ser publicados na seção de artigos,
              informações técnicas e de mercado, bem como lançamento de produtos
              e afins, da marca Siber, da empresa Kerry e seus parceiros.
            </li>
            <li>
              Produtos: Será disponibilizado o portfólio de produtos
              Siber/Kerry, para que o usuário se informe sobre os produtos e
              suas aplicações, verificando os ingredientes desses produtos,
              tabela nutricional e informações legais.
            </li>
          </ol>
          <p class="mt-2">
            O <b>Aplicativo Siber</b> tem caráter exclusivamente e
            predominantemente informativo, especialmente para a divulgação
            relativas aos produtos, receitas e receituários, artigos e cálculos
            de ingredientes para receitas, portanto, o cadastro do
            <b>USUÁRIO</b> não implica e/ou implicará na obrigatoriedade de
            prestação ou concessão de qualquer serviço ou fornecimento de
            qualquer produto por parte da <b>Siber da marca Kerry</b>, assim
            como qualquer outro, salvo os benefícios efetivamente concedidos ao
            <b>USUÁRIO</b> nos termos ora previstos.
          </p>
        </div>
        <div class="text-holder mt-3">
          <p><b>4) </b><span class="title">DA FORMALIZAÇÃO DO ACEITE</span></p>
          <p class="mt-2">
            O cadastro no <b>Aplicativo SIBER</b> é rápido, fácil e seguro. O
            <b>USUÁRIO</b>
            deverá fornecer algumas informações solicitadas referentes a si
            mesmo e seu estabelecimento necessários para a sua identificação,
            interação, fruição de benefícios e criação de conta individual com
            “login de usuário” e “senha”.
          </p>
          <p class="mt-2">
            Em caso de esquecimento da senha, o <b>USUÁRIO</b> poderá
            substitui-la acessando o link “Troca de Senha” de maneira rápida e
            segura.
          </p>
        </div>
        <div class="text-holder mt-3">
          <p><b>5) </b><span class="title">DA FORMALIZAÇÃO DO ACEITE</span></p>
          <p class="mt-2">
            Ao realizar o cadastro, o <b>USUÁRIO</b> concorda, de livre e
            espontânea vontade, em consentir, sem quaisquer vícios, que a
            <b>Kerry</b> trate os <b>Dados Pessoais</b> fornecidos para todas as
            finalidades descritas no item 3 acima, conforme a sua
            <b>Política de Privacidade</b>.
          </p>
          <p class="mt-2">
            A decisão de fornecer qualquer imagem ou foto é única e exclusiva do
            <b>USUÁRIO</b>, ciente de que poderá não fazê-lo caso não se sinta
            confortável, contudo, se a participação em determinada promoção ou
            campanha tiver como condição a disponibilização de imagens/fotos
            pelo <b>USUÁRIO</b>,
            <u
              >este está desde já integralmente ciente e expressamente de acordo
              que não poderá participar da mencionada promoção ou campanha,
              tampouco reclamar por qualquer prejuízo sofrido em relação a não
              participação, de qualquer natureza.</u
            >
          </p>
        </div>
        <div class="text-holder mt-3">
          <p>
            <b>6) </b
            ><span class="title"
              >DA LICENÇA DE USO E DA PROPRIEDADE INTELECTUAL</span
            >
          </p>
          <p class="mt-2">
            O <b>USUÁRIO</b> recebeu uma licença de uso limitada, não
            transferível, não exclusiva, livre de royalties e revogável para
            realizar download, instalar, executar e utilizar o
            <b>Aplicativo Siber</b> em seu telefone móvel para a finalidade a
            que se destina, razão pela qual fica proibido:
          </p>
          <p class="mt-2">
            (a) utilizar o <b>Aplicativo SIBER</b> para a finalidade diversa
            daquela para a qual foi disponibilizado;
          </p>
          <p class="mt-1">
            (b) realizar engenharia reversa, copiar, reproduzir, no todo ou em
            parte, qualquer código e design do Aplicativo;
          </p>
          <p class="mt-1">
            (c) copiar, no todo ou em parte, o nome e a logomarca
            <b>SIBER E KERRY, receitas e demais informações ali expressas.</b>
          </p>
          <p class="mt-2">
            O <b>USUÁRIO</b> não adquire, por meio do presente
            <b>Termos de Uso</b> e/ou da <b>Política de Privacidade</b>, nenhum
            direito de propriedade intelectual ou quaisquer outros direitos
            exclusivos, incluindo patentes, desenhos, marcas, direitos autorais
            ou direitos sobre informações confidenciais ou segredos de negócio,
            sobre ou relacionados ao <b>Aplicativo SIBER</b>, bem como à base de
            dados, os quais são de propriedade única e exclusiva da
            <b>Kerry</b>, ressalvada titularidade do <b>USUÁRIO</b> sobre os
            seus <b>Dados Pessoais</b>.
          </p>
          <p class="mt-2">
            Todo e qualquer conteúdo disponibilizado no <b>Aplicativo SIBER</b>,
            bem como toda e qualquer base de dados constituída pela
            <b>Kerry</b> pertencem unicamente e exclusivamente à <b>Kerry</b> e
            <b>Kerry</b> Group Confidential são protegidos pela legislação
            brasileira vigente e aplicável, especialmente no que se refere à
            propriedade intelectual e aos direitos autorais. Na qualidade de
            exclusivo titular da propriedade intelectual da citada base de
            dados, a <b>Kerry</b> poderá utilizá-la livremente, à sua faculdade,
            e da forma que melhor atenda aos seus interesses, ressalvado os
            <b>Dados Pessoais</b> dos <b>USUÁRIO</b>, cuja utilização seguirá o
            quanto disposto neste instrumento, na
            <b>Política de Privacidade</b>, na Lei Geral de Proteção de
            <b>Dados Pessoais</b> - Lei nº 13.709/2018 e demais legislações
            brasileiras aplicáveis e vigentes.
          </p>
          <p class="mt-2">
            A <b>Kerry</b> poderá, a seu exclusivo critério, a qualquer tempo e
            sem necessidade de comunicação prévia e expressa ao <b>USUÁRIO</b>:
            (i) encerrar ou suspender, total ou parcialmente, a qualquer
            momento, o acesso do <b>USUÁRIO</b> ao <b>Aplicativo SIBER</b>,
            quando o referido acesso ou cadastro violar as condições
            estabelecidas nestes <b>Termos de Uso</b> e/ou na Política de
            Privacidade; (ii) excluir, total ou parcialmente, as informações
            cadastradas pelo <b>USUÁRIO</b> que não estejam em consonância com
            as disposições destes <b>Termos de Uso</b>.
          </p>
          <p class="mt-2">
            Poderá, ainda, a <b>Kerry</b>, a seu exclusivo critério e faculdade,
            a qualquer momento, suspender, modificar e/ou encerrar as atividades
            do <b>Aplicativo SIBER</b>, mediante comunicação prévia ao
            <b>USUÁRIO</b>, salvo nas hipóteses de ato de autoridade pública
            competente, caso fortuito ou força maior, nos termos da lei, que
            resulte na imediata suspensão, modificação ou encerramento das
            atividades.
          </p>
          <p class="mt-2">
            O desrespeito à propriedade intelectual da <b>Kerry</b> resultará no
            imediato BLOQUEIO do <b>USUÁRIO</b> ao aplicativo e
            responsabilização civil, administrativa e criminal do
            <b>USUÁRIO</b> infrator, nos termos da lei.
          </p>
        </div>
        <div class="text-holder mt-3">
          <p>
            <b>7) </b><span class="title">DO CANCELAMENTO PELO USUÁRIO</span>
          </p>
          <p class="mt-2">
            O <b>USUÁRIO</b>, a qualquer momento, poderá solicitar o
            cancelamento da sua conta através do email:
            <a class="link" href="mailto:app@tgtstudio.com.br"
              >app@tgtstudio.com.br</a
            >, ciente de que se o fizer, perderá todos os dados cadastradas, bem
            como informações de produtos, receituários e demais informações
            contidas no aplicativo, assim como será automaticamente excluído de
            qualquer promoção ou campanha que tenha se inscrito e ainda não
            tenha sido concluída, sem que faça jus a qualquer compensação ou
            indenização de qualquer natureza.
          </p>
        </div>
        <div class="text-holder mt-3">
          <p><b>8) </b><span class="title">DOS DIREITOS DO USUÁRIO</span></p>
          <p class="mt-2">
            A <b>KERRY</b> garante ao <b>USUÁRIO</b>, no que diz respeito ao
            processamento de seus dados pessoais, os seguintes direitos:
          </p>
          <p class="mt-2">
            a) A confirmação da existência de tratamento de seus dados pessoais;
          </p>
          <p class="mt-2">
            b) O acesso aos dados pessoais coletados por meio do
            <b>Aplicativo SIBER</b>;
          </p>
          <p class="mt-2">
            c) A correção de seus dados, caso estes estejam incompletos,
            inexatos ou desatualizados;
          </p>
          <p class="mt-2">
            d) A anonimização, bloqueio ou eliminação de dados desnecessários,
            excessivos ou tratados em desconformidade com a legislação
            brasileira aplicável;
          </p>
          <p class="mt-2">
            e) A portabilidade de seus dados pessoais para si ou para terceiro,
            mediante requisição expressa feita pelo <b>USUÁRIO</b>;
          </p>
          <p class="mt-2">
            f) A eliminação dos dados tratados com o seu consentimento;
          </p>
          <p class="mt-2">
            g) A obtenção de informações sobre as entidades públicas ou privadas
            com as quais <b>KERRY</b> compartilhou seus dados; e,
          </p>
          <p class="mt-2">
            h) Informações sobre a possibilidade de não fornecer o seu
            consentimento, bem como de ser informado sobre suas consequências,
            em caso de negativa. Nesse sentido, para que o <b>USUÁRIO</b> possa
            exercer seus direitos, basta que envie e-mail para o endereço equipe
            <a class="link" href="mailto: lgpd@lbca.com.br">lgpd@lbca.com.br</a>
            apontando suas dúvidas e/ou requerimentos relacionados a seus dados
            pessoais, sendo certo que a <b></b>
            empregará os melhores esforços para atendê-lo no menor tempo
            possível.
          </p>
        </div>
        <div class="text-holder mt-3">
          <p>
            <b>9) </b
            ><span class="title"
              >REGRAS PARA A UTILIZAÇÃO DO APLICATIVO SIBER</span
            >
          </p>
          <p class="mt-2">
            Por meio do aceite aos presentes Termos, o <b>USUÁRIO</b> declara:
          </p>
          <p class="mt-2">
            i) ser considerado civilmente capaz à luz da legislação brasileira,
            ou estar devidamente autorizado por seus responsáveis legais a
            aceitar os presentes Termos;
          </p>
          <p class="mt-2">
            ii) estar ciente de que é o único e exclusivo responsável pela
            utilização de que fizer do <b>Aplicativo SIBER</b>, por meio de suas
            credenciais de acesso, especialmente no tocante ao fornecimento de
            informações, que deverá ser realizado de forma a garantir sua
            atualidade, exatidão e veracidade;
          </p>
          <p class="mt-2">
            iii) reconhecer que, por iniciativa própria, aceita e se
            responsabiliza por quaisquer consequências advindas de sua
            utilização do <b>Aplicativo SIBER</b>, respondendo por qualquer
            reivindicação que venha a ser apresentada à <b>KERRY</b>, judicial
            ou extrajudicialmente, em relação a quaisquer direitos envolvidos,
            morais e/ou materiais, causados à <b>KERRY</b> ou a terceiros;
          </p>
          <p class="mt-2">
            iv) compreender que o <b>Aplicativo SIBER</b> não deve ser utilizado
            para quaisquer fins ilícitos, consoante disposto na legislação
            brasileira;
          </p>
          <p class="mt-2">
            v) estar ciente de que não poderá inserir no
            <b>Aplicativo SIBER</b> qualquer conteúdo ilícito e/ou capaz de
            violar direitos da <b>KERRY</b>
            e/ou de terceiros; e
          </p>
          <p class="mt-2">
            vi) ter ciência de que não poderá ser inserido qualquer conteúdo ou
            material capaz de incorporar elementos nocivos ao
            <b>Aplicativo SIBER</b>, por quaisquer meios e formas capazes de
            impedir o normal funcionamento do <b>Aplicativo SIBER</b>, bem como
            de prejudicar os sistemas informáticos da <b>KERRY</b> ou de
            terceiros e/ou danificar documentos e/ou arquivos eletrônicos nestes
            armazenados.
            <u
              >Por meio do aceite aos presentes Termos, você reconhece que, em
              qualquer hipótese, é o único e exclusivo responsável pela
              utilização do <b>Aplicativo SIBER</b>, isentando a <b>KERRY</b> de
              qualquer responsabilidade por danos e prejuízos decorrentes do uso
              indevido deste, inclusive em face de terceiros.</u
            >
          </p>
        </div>
        <div class="text-holder mt-3">
          <p>
            <b>10) </b
            ><span class="title"
              >A <b>KERRY</b> não responderá, em hipótese alguma:</span
            >
          </p>
          <p class="mt-2">
            a) pelo cadastrado/fornecimento pelo USUÁRIO de dados, imagens,
            fotos falsas, inverídicas ou de terceiros;
          </p>
          <p class="mt-2">b) por danos causados a terceiros pelo USUÁRIO;</p>
          <p class="mt-2">
            c) pela perda da senha pelo USUÁRIO ou disponibilização desta a
            terceiros e suas consequências, uma vez que a senha é de uso
            individual, intransferível e exclusivo do USUÁRIO;
          </p>
          <p class="mt-2">
            d) pelo acesso ao Aplicativo SIBER, via login e senha, por
            terceiros, sem autorização do USUÁRIO, ainda que decorrentes da
            perda/roubo/furto/extravio do aparelho celular ou computador de
            propriedade do USUÁRIO.
          </p>
        </div>
        <div class="text-holder mt-3">
          <p><b>11) </b><span class="title">DAS ATUALIZAÇÕES</span></p>
          <p class="mt-2">
            Qualquer atualização destes Termos de Uso em decorrência do
            aperfeiçoamento do Aplicativo SIBER e/ou adequação à legislação
            brasileira vigente será imediatamente comunicada ao USUÁRIO, via
            Aplicativo.
          </p>
        </div>
        <div class="text-holder mt-3">
          <p><b>12) </b><span class="title">DAS DISPOSIÇÕES GERAIS</span></p>
          <p class="mt-2">
            Todas as comunicações feitas pelo USUÁRIO à KERRY serão consideradas
            válidas quando realizadas por meio do e-mail app@tgtstudio.com.br;
          </p>
          <p class="mt-2">
            Se qualquer disposição ou disposições destes Termos de Uso forem
            consideradas inválidas, nulas, ilegais ou não aplicáveis, a
            validade, legalidade e aplicabilidade das demais disposições devem
            permanecer em pleno vigor.
          </p>
          <p class="mt-2">
            Estes Termos de Uso devem ser regidos por e interpretados de acordo
            com a legislação do Brasil.
          </p>
          <p class="mt-2">
            Quaisquer disputas ou controvérsias oriundas de quaisquer atos
            praticados no âmbito da utilização dos sítios e/ou aplicativos pelos
            usuários, inclusive com relação ao descumprimento dos Termos de Uso
            e Política de Privacidade ou pela violação dos direitos da
            Administração Pública Federal, de outros Usuários e/ou de terceiros,
            inclusive direitos de propriedade intelectual, de sigilo e de
            personalidade, serão processadas na Comarca de Campinas/SP.
          </p>
          <p class="mt-2">
            Sem prejuízo de qualquer outra via de recurso administrativo ou
            judicial, o USUÁRIO tem direito a apresentar reclamação à Autoridade
            Nacional de Proteção de Dados (ANPD), com base no art. 18, parágrafo
            único, da Lei nº 13.709, de 2018 (LGPD).
          </p>
        </div>
        <div class="text-holder mt-3">
            <p><b>Última atualização: <u>02 de maio de 2022.</u></b></p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Header from "../components/Header";
export default {
  components: {
    Header,
  },
};
</script>

<style lang="scss" scoped>
.text-holder {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .title {
    font-family: fontBold;
    font-size: 1.2rem;
    text-align: left;
    margin-bottom: 15px;
    text-transform: uppercase;
    color: var(--dark4);
    text-decoration-line: underline;
  }
  .main-title {
    text-align: center;
  }
  p {
    font-family: fontRegular;
    font-size: 1rem;
    line-height: 1.4;
    color: var(--dark4);
  }
  ol li {
    margin-top: 0.5rem;
    font-family: fontRegular;
    line-height: 1.4;
    font-size: 1rem;
    color: var(--dark4);
  }
  u {
    text-decoration-line: underline;
  }
}
.link {
  margin-top: 0.3em;
  font-family: fontRegular;
  font-size: 1.1rem;
  line-height: 1.3;
  color: blue;
  border-bottom: 1px solid;
}
.mt-4 {
  margin-top: 8.3rem;
}
</style>
